<template>
  <div class="text-left">
    <div class="flex tete p-6 text-c5 text-c14" @click="activePosition = -1">
      <div class="w-1/2">
        Articles
      </div>
      <div class="w-1/2 flex">
        <div class="w-1/5 ml-4">
          Qté
        </div>
        <div class="w-1/3 ml-4">
          Prix unitaire
        </div>
        <div class="w-1/3 ml-4">
          Prix total
        </div>
      </div>
    </div>

    <div class="diviser mt-2" />

    <div
        class="mt-6"
    >
      <div class="w-full flex mt-6" v-for="(i, index) in devis" :key="index" @click="open(index)">
        <div class="flex w-1/2 items-center">
          <icon
              v-if="i.product.type === 'GLASS'"
            :data="icons.verre"
            height="40"
            width="40"
            class="mr-2 cursor-pointer"
            original
          />
          <icon
              v-if="i.product.type === 'FRAMES'"
              :data="icons.monture"
              height="40"
              width="40"
              class="mr-2 cursor-pointer"
              original
          />
          <icon
              v-if="i.product.type === 'ACCESSORY'"
              :data="icons.etuie"
              height="40"
              width="40"
              class="mr-2 cursor-pointer"
              original
          />
          <icon
              v-if="i.product.type === 'SERVICE'"
              :data="icons.prestation"
              height="40"
              width="40"
              class="mr-2 cursor-pointer"
              original
          />

          <icon
              v-if="i.product.type === 'TREATMENT'"
              :data="icons.treatment"
              height="40"
              width="40"
              class="mr-2 cursor-pointer"
              original
          />

          <div
              v-if="i.product.type === 'GLASS'"
              class="ml-4 w-4/5"
          >
            <div class="text-c14 font-c5">{{ i.product.glassType }} | {{ i.product.ref }}</div>
            <div>
              <span class="font-c7" v-if="i.meta !== undefined && i.meta !== null"> {{i.meta.oeil}}: </span>

              <span class="font-c4 text-c11 text-90 ml-2" v-if="i.meta === undefined || i.meta === null && donnes.meta !== null && donnes.meta.treatmentData !== undefined && donnes.meta.treatmentData !== null">
                Mat = {{i.product.glassMaterial}}; Indice = {{i.product.indice}}; S/couche = {{donnes.meta.treatmentData.sousCouche}};
                T.Dur = {{donnes.meta.treatmentData.dur}}; T.AR = {{donnes.meta.treatmentData.ar}};
                Sph = {{donnes.meta.treatmentData.sphere}}; Cyl = {{donnes.meta.treatmentData.cylindre}}; Add = {{donnes.meta.treatmentData.addition}};
                Axe = {{donnes.meta.treatmentData.axe}}°; Diamètre = {{donnes.meta.treatmentData.diametre}};

                <span v-if="donnes.meta.treatmentData.color !== undefined && donnes.meta.treatmentData.color !== ''">
                  Couleur = {{donnes.meta.treatmentData.color}}
                </span>
              </span>

              <span
                  class="font-c4 text-c11 text-90 ml-2"
                  v-if="i.meta !== undefined && i.meta !== null && i.meta.treatmentData !== undefined && i.meta.treatmentData !== null"
              >
                Mat = {{i.product.glassMaterial}}; Indice = {{i.product.indice}}; S/couche = {{i.meta.treatmentData.sousCouche}};
                T.Dur = {{i.meta.treatmentData.dur}}; T.AR = {{i.meta.treatmentData.ar}};
                Sph = {{i.meta.treatmentData.sphere}}; Cyl = {{i.meta.treatmentData.cylindre}}; Add = {{i.meta.treatmentData.addition}};
                Axe = {{i.meta.treatmentData.axe}}°; Diamètre = {{i.meta.treatmentData.diametre}};

                <span v-if="i.meta.treatmentData.color !== undefined && i.meta.treatmentData.color !== ''">
                  Couleur = {{i.meta.treatmentData.color}}
                </span>
              </span>
            </div>
          </div>

          <div
              v-if="i.product.type !== 'GLASS'"
              class="ml-4 w-4/5"
          >
            <div class="text-c14 font-c5">
              {{ i.product.name }}
            </div>
          </div>
        </div>

        <div class="w-1/2 flex">
          <div class="w-1/5 ml-4">
            <div class="w-3/4">
              <bouton
                  v-if="i.quantity"
                  :label="'X ' + i.quantity"
                  size="12px"
                  height="35px"
                  color="#828282"
                  background="#F5F5F5"
              />

              <selectFiltre :option="optionQte" @info="retourQte" value="" size="12px" v-if="activePosition === index"/>
            </div>
          </div>

          <div class="w-1/3">
              <bouton
                  v-if="i.amount"
                  :label="i.amount.toLocaleString()"
                  size="14px"
                  height="35px"
                  color="#000"
                  background="#F5F5F5"
              />
          </div>

          <div class="w-1/3 ml-4">
              <bouton
                  :label="calcule(i)"
                  size="14px"
                  height="35px"
                  color="#000"
                  background="#F5F5F5"
              />
          </div>
        </div>
      </div>
    </div>

    <diviser
      height="1.8px"
      color="#5138EE"
      class="mt-10"
    />
  </div>
</template>

<script>
import bouton from '../../helper/add/button'
import diviser from '../../helper/add/divider'
import verre from '../../../assets/icons/verre.svg'
import treatment from '../../../assets/icons/surface.svg'
import prestation from '../../../assets/icons/prestation.svg'
import monture from '../../../assets/icons/monture.svg'
import etuie from '../../../assets/icons/etuie.svg'
import selectFiltre from '../../helper/form/selectFiltre'

export default {
  name: "Index",

  components: {
    bouton,
    diviser,
    selectFiltre
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        verre,
        monture,
        etuie,
        prestation,
        treatment
      },
      devis: null,
      optionQte: ['X 1', 'X 2', 'X 3', 'X 4', 'X 5'],
      activePosition: -1
    }
  },

  created () {
    this.devis = this.donnes.articles
  },

  methods: {
    retourAdd () {
      this.$router.push('/panier')
    },

    retourQte (answer) {
      this.devis.panier[this.activePosition].qte = answer.replace('X ', '')
      this.$store.dispatch('saveQuote', this.devis)
      console.log(this.$store.getters.trackQuote)
      this.open(this.activePosition)
    },

    open (index) {
      // if (this.activePosition !== index) {
      //   this.activePosition = index
      // } else {
      //   this.activePosition = -1
      // }
      console.log(index)
    },

    calcule (u) {
        let amount = 0
        amount = amount + (parseInt(u.amount) * parseInt(u.quantity))
        return amount.toLocaleString()
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.diviser{
  height: 1px;
  background-color: #DCDCE4;
}
.tete{
  color: #5138EE;
  background: linear-gradient(180deg, #EFF4FF 0%, rgba(239, 244, 255, 0) 100%);
  border-radius: 10px;
  border-top: 5px solid #5138EE;
}

</style>
